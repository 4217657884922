import React, {useEffect, useRef, useState} from 'react';
import http, {setHeaders} from 'libs/http';
import { toast } from 'react-toastify';
import Input from 'components/Input';
import Button from 'components/Button';
import { useForm } from 'react-hook-form';
import TextEditor from 'components/TextEditor';
import { ElementLoader } from 'components/Loaders';
import { modalActions } from 'store/modal/modal.store';
import { useDispatch, useSelector } from 'react-redux';
import {faPlus, faTrashCan} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BenefitDocumentInput from 'modules/benefit/UI/BenefitDocumentInput';
import {
  addBenefit,
  deleteImage,
  getBenefits,
  getCategories,
  uploadImages
} from 'modules/benefit/services/benefit.store';
import Datepicker from "components/Datepicker";
import SearchableDropdown from "components/SearchableDropdown";
import CheckBox from "components/Checkbox";
import {formatDate} from "libs/datetime";
import { MUNICIPALITIES_SERBIA, MUNICIPALITIES_MONTENEGRO } from 'libs/locations';

export const TAX_TREATMENTS: any = [
  { name: 'Oporeziv benefit', value: 'Oporeziv benefit' },
  { name: 'Neoporeziv benefit', value: 'Neoporeziv benefit' },
  { name: 'Neoporeziv benefit do definisanog limita', value: 'Neoporeziv benefit do definisanog limita' },
]

interface AddBenefitFormProps {
  copyData?: any;
}

const AddBenefitForm = ({ copyData }: AddBenefitFormProps) => {

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    getFieldState,
    formState: { errors, isValid, touchedFields },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      name: '',
      price: '',
      companyPrice: '',
      discount: '',
      keywords: '',
      validFrom: '',
      validTo: '',
      note: '',
      category: '',
      subcategory: '',
    },
  });

  const dispatch: any = useDispatch();
  const user: any = useSelector((state: any) => state.authStore.user);
  const categories: any = useSelector((state: any) => state.benefitStore.categories);
  const [loading, setLoading] = useState(false);
  const [promoMaterial, setPromoMaterial] = useState<any>([]);
  const [images, setImages] = useState<any>([]);
  const importRef = useRef<any>(null);
  const importImageRef = useRef<any>(null);
  const [description, setDescription] = useState<any>('');
  const [shortDescription, setShortDescription] = useState<any>('');
  const [instructions, setInstructions] = useState<any>('');
  const [note, setNote] = useState<any>('');
  const [limitations, setLimitations] = useState<any>('');
  const [category, setCategory] = useState<any>(null);
  const [subcategory, setSubcategory] = useState<any>(null);
  const [municipality, setMunicipality] = useState<any>(null);
  const [isNfc, setIsNfc] = useState<any>(false);
  const [specialOffers, setSpecialOffers] = useState<any>(false);
  const [teamBuilding, setTeamBuilding] = useState<any>(false);

  useEffect(() => {
    console.log(copyData, 'Object.keys(copyData).length > 0')
    if (copyData) {
      if (copyData.price) setValue('price', copyData.price);
      if (copyData.companyPrice) setValue('companyPrice', copyData.companyPrice);
      if (copyData.discount) {setValue('discount', copyData.discount);}
      if (copyData.keywords) setValue('keywords', copyData.keywords);
      if (copyData.validFrom) setValue('validFrom', copyData.validFrom);
      if (copyData.validTo) setValue('validTo', copyData.validTo);
      if (copyData.location) setMunicipality({name: copyData.location, value: copyData.location});
      if (copyData.description) setDescription(copyData.description);
      if (copyData.shortDescription) setShortDescription(copyData.shortDescription);
      if (copyData.instructions) setInstructions(copyData.instructions);
      if (copyData.limitations) setLimitations(copyData.limitations);
      if (copyData.note) setNote({name: copyData.note, value: copyData.note});
      if (copyData.category) {
        const target = categories.find((cat: any) => cat.id === copyData.category);
        setCategory(target);
      }
      if (category && copyData.subcategory) {
        const target = category.children.find((sub: any) => sub.id === copyData.subcategory);
        setSubcategory(target);
      }
      if (copyData.nfc) setIsNfc(copyData.nfc);
      if (copyData.specialOffer) setSpecialOffers(copyData.specialOffer);
      if (copyData.teamBuilding) setTeamBuilding(copyData.teamBuilding);
      if (copyData.promoMaterial.length > 0) setPromoMaterial(copyData.promoMaterial);
      if (copyData.pictures.length > 0) setImages(copyData.pictures);
    }
  }, [copyData, category]);

  const onSubmit = async (data: any) => {
    if (isValid) {
      let payload: any = {};

      payload.provider = user.company.id;
      payload.nfc = isNfc;
      payload.special_offer = specialOffers;
      payload.is_team_building = teamBuilding;
      if (data.name) payload.name = data.name;
      if (data.price) payload.price = data.price;
      if (data.companyPrice) payload.company_price = data.companyPrice;
      if (data.discount) payload.discount = data.discount;
      if (data.keywords) payload.keywords = data.keywords;
      if (data.validFrom) payload.valid_from = data.validFrom;
      if (data.validTo) payload.valid_to = data.validTo;
      if (description) payload.description =  description;
      if (shortDescription) payload.short_description = shortDescription;
      if (instructions) payload.instructions = instructions;
      if (limitations) payload.limitations = limitations;
      if (note) payload.note = note.value;
      if (category) payload.category = category.id;
      if (subcategory) payload.subcategory = subcategory.id;
      if (municipality) payload.location = municipality.value;

      if (promoMaterial.length > 0) {
        payload.promo_material = promoMaterial.map((e: any) => e.id).join(',');
      }

      if (images.length > 0) {
        payload.pictures = [...images];
      }

      const formData: FormData = new FormData();
      for (const key in payload) {
        formData.append(key, payload[key]);
      }


      try {
        const data = await dispatch(addBenefit(formData));
        uploadImagesHandler(data.id);
        dispatch(modalActions.closeModal());
        toast.success('Uspešno ste dodali benefit!');
      } catch (e: any) {
        toast.error('Nešto nije u redu!');
      }
    }
  }

  const openUploadPrompt = (): void => {
    importRef.current.click();
  }

  const openUploadImagePrompt = (): void => {
    importImageRef.current.click();
  }

  const setImageHandler = async (e: any): Promise<any> => {
    const selectedImages = [...images];
    selectedImages.push({
      position: 0,
      image: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0]
    });
    setImages(selectedImages);
  }

  const uploadImagesHandler = async (benefitId: number): Promise<any> => {
    for (const img of images) {
      let payload: FormData = new FormData();
      payload.append('image', img.file);
      payload.append('position', '0');
      payload.append('benefit', String(benefitId));

      try {
        await setHeaders({ 'content-type': 'multipart/form-data' });
        await dispatch(uploadImages(payload));
        await setHeaders({ 'content-type': 'application/json' });
      } catch (e: any) {
        console.log(e, 'ERR');
      }
    }
    dispatch(getBenefits(false));
  }

  const uploadPromoHandler = async (e: any): Promise<any> => {
    let req = new FormData()
    req.append('title', 'promo');
    req.append('file', e.target.files[0]);

    try {
      setHeaders({ 'content-type': 'multipart/form-data' });
      const { data } = await http.post('v2/admin/promo/', req);
      setHeaders({ 'content-type': 'application/json' });
      const promo = [...promoMaterial, data];
      setPromoMaterial(promo);
    } catch (e: any) {
      toast.error(e.data.detail);
    }
  }

  const deletePromoHandler = async (promoId: number): Promise<any> => {
    try {
      await http.delete(`v2/admin/promo/${promoId}/`);
      const promo = promoMaterial.filter((e: any) => e.id != promoId);
      setPromoMaterial(promo);
    } catch (e: any) {
      toast.error('Nešto nije u redu!');
    }
  };

  const deleteImageHandler = async (image: any): Promise<any> => {
    const imgs = images.filter((e: any) => e.image !== image);
    setImages(imgs);
  }

  const descriptionHandler = (e: any): void => {
    setDescription(e);
  }

  const shortDescriptionHandler = (e: any): void => {
    setShortDescription(e);
  }

  const limitationsHandler = (e: any): void => {
    setLimitations(e);
  }

  const instructionsHandler = (e: any): void => {
    setInstructions(e);
  }

  const noteHandler = (note: any): void => {
    setNote(note);
  }

  const categoryHandler = (category: any): void => {
    setCategory(category);
  }

  const municipalityHandler = (municipality: any): void => {
    setMunicipality(municipality);
  }

  const subcategoryHandler = (category: any): void => {
    setSubcategory(category);
  }

  const closeForm = () => {
    dispatch(modalActions.closeModal());
  }

  const startDateHandler = (date: any): void => {
    setValue('validFrom', formatDate(date, 'YYYY-MM-DD'));
  };

  const endDateHandler = (date: any): void => {
    setValue('validTo', formatDate(date, 'YYYY-MM-DD'));
  };

  const toggleNfc = (): void => {
    setIsNfc(!isNfc);
  };

  const toggleSpecialOffers = (): void => {
    setSpecialOffers(!specialOffers);
  };

  const toggleTeamBuilding = (): void => {
    setTeamBuilding(!teamBuilding);
  };



  useEffect(() => {
    dispatch(getCategories());
  }, []);

  return (
    <div className="relative">
      <div className="p-7 space-y-7">
        <div>
          <Input
            type='text'
            label="Naziv"
            autoFocus
            height='medium'
            placeholder="Unesi naziv"
            isTouched={touchedFields.name}
            error={errors.name}
            validationRules={register('name', {
              required: {
                value: true,
                message: "Obavezno polje",
              },
            })}
            onChange={() => null}
          />
        </div>
        <div>
          <Input
            type='number'
            label="Cena"
            height='medium'
            placeholder="Unesi cenu"
            isTouched={touchedFields.price}
            error={errors.price}
            validationRules={register('price', {
              required: {
                value: true,
                message: "Obavezno polje",
              },
              min: {
                value: 0,
                message: 'Minimalan iznos je 1'
              }
            })}
            onChange={() => null}
          />
        </div>
        <div>
          <Input
            type='number'
            label="Procenat poreza i doprinosa"
            height='medium'
            placeholder="Unesi procenat poreza i doprinosa"
            isTouched={touchedFields.companyPrice}
            error={errors.companyPrice}
            validationRules={register('companyPrice')}
            onChange={() => null}
          />
        </div>
        <div>
          <Input
            type='number'
            label="Popust"
            height='medium'
            placeholder="Unesi popust"
            isTouched={touchedFields.discount}
            error={errors.discount}
            validationRules={register('discount')}
            onChange={() => null}
          />
        </div>
        <div>
          <Input
            type='text'
            label="Ključne reči"
            height='medium'
            placeholder="Unesi ključne reči"
            isTouched={touchedFields.keywords}
            error={errors.keywords}
            validationRules={register('keywords')}
            onChange={() => null}
          />
        </div>
        <div className="w-full flex items-center justify-between">
          <div className="w-1/2">
            <Datepicker
              label="Važi od"
              value={getValues('validFrom')}
              format="dd.MM.yyyy"
              placeholder="Važi od"
              onChange={(value: any) => startDateHandler(value)}
            />
          </div>
          <div className="w-1/2">
            <Datepicker
              label="Važi do"
              value={getValues('validTo')}
              format="dd.MM.yyyy"
              placeholder="Važi do"
              onChange={(value: any) => endDateHandler(value)}
            />
          </div>
        </div>
        {/*<div>*/}
        {/*  <Input*/}
        {/*    type='text'*/}
        {/*    label="Lokacija"*/}
        {/*    height='medium'*/}
        {/*    placeholder="Unesi lokaciju"*/}
        {/*    isTouched={touchedFields.location}*/}
        {/*    error={errors.location}*/}
        {/*    validationRules={register('location')}*/}
        {/*    onChange={() => null}*/}
        {/*  />*/}
        {/*</div>*/}
        <div>
          <SearchableDropdown
            value={municipality}
            label="Lokacija"
            idField="value"
            valueToShow="name"
            placeholder="Lokacija"
            options={user.country === 1 ? MUNICIPALITIES_SERBIA : MUNICIPALITIES_MONTENEGRO}
            onChange={(municipality: any) => municipalityHandler(municipality)}
          />
        </div>
        <div className="h-60">
          <TextEditor
            label="Opis"
            value={description}
            onChange={descriptionHandler}
          />
        </div>
        <div className="h-60">
          <TextEditor
            label="Kratak opis"
            value={shortDescription}
            onChange={shortDescriptionHandler}
          />
        </div>
        <div className="h-64 pt-2">
          <TextEditor
            label="Instrukcije"
            value={instructions}
            onChange={instructionsHandler}
          />
        </div>
        <div className="h-64">
          <TextEditor
            label="Ograničenja"
            value={limitations}
            onChange={limitationsHandler}
          />
        </div>
        <div>
          {/*<TextEditor*/}
          {/*  label="Informacija o poreskom tretmanu"*/}
          {/*  onChange={noteHandler}*/}
          {/*/>*/}
          <SearchableDropdown
            value={note}
            label="Informacija o poreskom tretmanu"
            idField="value"
            valueToShow="name"
            placeholder="Informacija o poreskom tretmanu"
            options={TAX_TREATMENTS}
            onChange={(note: any) => noteHandler(note)}
          />
        </div>
        <div className="flex items-center gap-x-4">
          <div className="w-1/2">
            <SearchableDropdown
              value={category}
              label="Kategorija"
              idField="id"
              valueToShow="name"
              placeholder="Kategorija"
              options={categories}
              onChange={(category: any) => categoryHandler(category)}
            />
          </div>
          <div className="w-1/2">
            <SearchableDropdown
              value={subcategory}
              idField="id"
              label="Potkategorija"
              disabled={!category}
              valueToShow="name"
              placeholder="Potkategorija"
              options={category ? category.children : []}
              onChange={(subcategory: any) => subcategoryHandler(subcategory)}
            />
          </div>
        </div>
        <div className="flex items-end gap-x-5">
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-1">
              Prilog
            </label>
            <div className="flex items-center gap-x-6">
              {promoMaterial.map((p: any) =>
                <BenefitDocumentInput key={p.id} promo={p} onDelete={() => deletePromoHandler(p.id)}/>
              )}
            </div>
          </div>
          <div className="pt-3">
            <input onChange={uploadPromoHandler} hidden type="file" ref={importRef}/>
            <Button
              bg="outline-primary"
              className="w-8 flex items-center justify-center gap-x-2"
              size="small"
              onClick={openUploadPrompt}
            >
              <FontAwesomeIcon icon={faPlus}/>
            </Button>
          </div>
        </div>
        <div className="flex items-end gap-x-5">
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-1">
              Slike
            </label>
            <div className="flex flex-wrap items-center gap-4">
              {images.map((img: any) =>
                <div className="flex items-end gap-x-2">
                  <img key={img.image} src={img.image} alt="" className="h-16 rounded-lg"/>
                  <div className="flex items-center">
                    <Button
                      bg="outline-warning"
                      className="w-8 mt-0.5 flex items-center justify-center gap-x-2"
                      size="small"
                      onClick={() => deleteImageHandler(img.image)}
                    >
                      <FontAwesomeIcon icon={faTrashCan}/>
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div>
            <div className="pt-3">
              <input onChange={setImageHandler} hidden type="file" ref={importImageRef}/>
              <Button
                bg="outline-primary"
                className="w-8 flex items-center justify-center gap-x-2"
                size="small"
                onClick={openUploadImagePrompt}
              >
                <FontAwesomeIcon icon={faPlus}/>
              </Button>
            </div>
          </div>
        </div>
        <div className="pt-3">
          <CheckBox
            title="IN-STORE"
            size="small"
            value={isNfc}
            textClassNames="font-medium"
            onChange={toggleNfc}
          />
        </div>
        <div>
          <CheckBox
            title="Specijalne ponude"
            size="small"
            value={specialOffers}
            textClassNames="font-medium"
            onChange={toggleSpecialOffers}
          />
        </div>
        {/*<div>*/}
        {/*  <CheckBox*/}
        {/*    title="Team building"*/}
        {/*    size="small"*/}
        {/*    value={teamBuilding}*/}
        {/*    textClassNames="font-medium"*/}
        {/*    onChange={toggleTeamBuilding}*/}
        {/*  />*/}
        {/*</div>*/}
      </div>
      <div
        className="flex items-center justify-between gap-x-5 sticky bottom-0 left-0 px-7 py-6 border-t w-full bg-white">
        <Button
          bg="white"
          className="w-full flex items-center justify-center gap-x-2"
          size="regular"
          onClick={closeForm}
        >
          Otkaži
        </Button>
        <Button
          bg="primary"
          className="w-full gap-x-2 relative"
          size="regular"
          disabled={loading}
          onClick={handleSubmit(onSubmit)}
        >
          Dodaj
          {loading && <ElementLoader color="white" size="small" classNames="flex items-center justify-end pr-2"/>}
        </Button>
      </div>
    </div>
  );
};

export default AddBenefitForm;