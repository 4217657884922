import React from 'react';
import GoogleMaps from 'components/GoogleMaps';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faLocationDot, faPhone, faEnvelope, faClock, faGlobe, faBuilding } from '@fortawesome/free-solid-svg-icons';

const Contact = () => {


  return(
    <div className="md:grid md:grid-cols-2 md:gap-4">
      <div className="mt-12 sm:mt-16 md:mt-0 mb-10 bg-white h-[700px] flex items-center p-5 rounded-md shadow-xl">
        <div className="w-full">
          <div className="flex items-center justify-between">
            <h2 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">
              ICD Group
            </h2>
            <img src="assets/logo2.png" className="w-40 h-20 mb-4"/>
          </div>
          <div className="mt-8 space-y-9">
            <div className="flex">
              <div className="flex-shrink-0">
                <FontAwesomeIcon icon={faLocationDot} className="mr-1 text-gray-500"/>
                {/*<font-awesome-icon icon="fa-solid fa-location-dot" className="h-6 w-6 mr-1 text-gray-500"/>*/}
              </div>
              <div className="ml-3 text-base text-gray-500">
                <p>
                  Majdanska Čukarica 16, Beograd, Srbija
                </p>
              </div>
            </div>
            <div className="flex">
              <div className="flex-shrink-0">
                <FontAwesomeIcon icon={faPhone} className="mr-1 text-gray-500"/>
              </div>
              <div className="ml-3 text-base text-gray-500">
                <a href="tel:+381 66 8027 033" className="hover:underline">
                  +381 66 8027 033
                </a>
              </div>
            </div>
            <div className="flex">
              <div className="flex-shrink-0">
                <FontAwesomeIcon icon={faPhone} className="mr-1 text-gray-500"/>
              </div>
              <div className="ml-3 text-base text-gray-500">
                <a href="tel:+381 64 2384 538" className="hover:underline">
                  +381 64 2384 538
                </a>
              </div>
            </div>
            <div className="flex">
              <div className="flex-shrink-0">
                <FontAwesomeIcon icon={faPhone} className="mr-1 text-gray-500"/>
              </div>
              <div className="ml-3 text-base text-gray-500">
                <a href="tel:+381 64 2493 032" className="hover:underline">
                  +381 64 2493 032
                </a>
              </div>
            </div>
            <div className="flex">
              <div className="flex-shrink-0">
                <FontAwesomeIcon icon={faPhone} className="mr-1 text-gray-500"/>
              </div>
              <div className="ml-3 text-base text-gray-500">
                <a href="tel:+382 68 595 555" className="hover:underline">
                  +382 68 595 555
                </a>
              </div>
            </div>
            <div className="flex">
              <div className="flex-shrink-0">
                <FontAwesomeIcon icon={faEnvelope} className="mr-1 text-gray-500"/>
              </div>
              <div className="ml-3 text-base text-gray-500">
                <a href="mailto:support@hob.rs" className="hover:underline">
                  support@hob.rs
                </a>
              </div>
            </div>
            <div className="flex">
              <div className="flex-shrink-0">
                <FontAwesomeIcon icon={faClock} className="mr-1 text-gray-500"/>
              </div>
              <div className="ml-3 text-base text-gray-500">
                <p>Radno vreme: &nbsp; Pon - Pet, 9 - 19h</p>
              </div>
            </div>
            <div className="flex">
              <div className="flex-shrink-0">
                <FontAwesomeIcon icon={faGlobe} className="mr-1 text-gray-500"/>
              </div>
              <div className="ml-3 text-base text-gray-500">
                <a href="https://hob.rs/" target="_blank" className="hover:underline">
                  www.hob.rs
                </a>
              </div>
            </div>

            <div className="flex">
              <div className="flex-shrink-0">
                <FontAwesomeIcon icon={faBuilding} className="mr-1 text-gray-500"/>
              </div>
              <div className="ml-3 text-base text-gray-500">
                <p>PIB: 110358663, &nbsp; MB: 21342408</p>
              </div>
            </div>


          </div>
        </div>
      </div>
      <div>
        <GoogleMaps/>
      </div>
    </div>
  );
};

export default Contact;