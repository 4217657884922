import React, {useEffect} from 'react';

import Input from 'components/Input';
import {toast} from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {login, getUser, authActions} from 'modules/auth/services/auth.store';

const Login = () => {

  const {
    register,
    setValue,
    handleSubmit,
    trigger,
    getFieldState,
    formState: { errors, isValid, touchedFields },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const dispatch: any = useDispatch();
  const navigate: any = useNavigate();

  const setEmailHandler = (value: string): void => {
    setValue('email', value);
  }

  const setPasswordHandler = (value: string): void => {
    setValue('password', value);
  }

  const onSubmit = async (payload: any): Promise<any> => {
    if (isValid) {
      try {
        await dispatch(login(payload));
        await fetchUser();
      } catch (e: any) {
        const errorKeys = Object.keys(e.data);

        errorKeys.forEach((key: any) => {
          e.data[key].forEach((err: any) => {
            toast.error(err);
          })
        })
      }
    }
  };

  const enterHandler = async (e: any) => {
    if (e.keyCode === 13) {
      await trigger('email');
      await trigger('password');
      handleSubmit(onSubmit);
    }
  };

  useEffect(() => {
    window.addEventListener('keypress', enterHandler);
  }, []);

  const fetchUser = async (): Promise<any> => {
    try {
      await dispatch(getUser());
      navigate('/');
      toast.success('Uspešno ste se ulogovali!');
    } catch (e: any) {
      toast.error(e);
      dispatch(authActions.logOut());
    }
  }


  return (
    <div className="flex items-center justify-center p-4">
      <div className="mt-20 w-full lg:w-[450px]">
        <img src="assets/hob-logo.png" alt="LOGO" className="h-36 mx-auto"/>
        <form className="mt-10 space-y-5">
          <div>
            <Input
              type='text'
              label="Email"
              autoFocus
              height='medium'
              placeholder="Enter email"
              isTouched={touchedFields.email}
              error={errors.email}
              validationRules={register('email', {
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/,
                  message: "Bad email format",
                },
                required: {
                  value: true,
                  message: "Required field",
                },
              })}
              onChange={setEmailHandler}
            />
          </div>
          <div>
            <Input
              type='password'
              label="Password"
              height='medium'
              placeholder="Enter password"
              error={errors.password}
              isTouched={touchedFields.password}
              validationRules={register('password', {
                required: {
                  value: true,
                  message: "Required field",
                },
                minLength: {
                  value: 8,
                  message: "Minimum eight characters",
                },
              })}
              onChange={setPasswordHandler}
            />
          </div>
          <div className="pt-4 space-y-2">
            <div>
              <button
                onClick={handleSubmit(onSubmit)}
                className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-base-600 hover:bg-base-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-base-500 animation duration-300 ease-in-out">
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <FontAwesomeIcon icon={faLock} className="h-5 w-5 text-base-500 group-hover:text-base-400"/>
              </span>
                Sign in
              </button>
            </div>
          </div>
          {/*<div className="mt-10 bg-red-500/15 p-4 rounded-lg text-sm">*/}
          {/*  <div className="flex items-center gap-x-2 font-medium">*/}
          {/*    <span className="h-5 w-5 rounded-full flex items-center justify-center bg-red-600/35">*/}
          {/*      <FontAwesomeIcon icon={faTimes} className="text-white"/>*/}
          {/*    </span>*/}
          {/*    <p className="text-red-700/90">There were errors with your request</p>*/}
          {/*  </div>*/}
          {/*  <ul className="p-3 pl-7 text-red-900/90 space-y-1">*/}
          {/*    <li>&bull; Email wrong</li>*/}
          {/*    <li>&bull; Email wrong</li>*/}
          {/*  </ul>*/}
          {/*</div>*/}
        </form>
      </div>
    </div>
  );
};

export default Login;