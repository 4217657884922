import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { authActions } from 'modules/auth/services/auth.store';
import { Route, Routes, useLocation, Outlet, Navigate } from 'react-router-dom';

import Login from 'pages/login';
import Contact from 'pages/contact';
import Benefits from 'pages/benefits';
import Transactions from 'pages/transactions';
import InStoreShopping from 'pages/in-store-shopping';
import PageNotFound from 'components/PageNotFound';


const ProtectedRoutes = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const token = localStorage.getItem('token');
  const user = localStorage.getItem('user');

  const tokenHandler = () => {
    if(token) dispatch(authActions.setToken(token));
    else dispatch(authActions.logOut());
  }

  useEffect(() => {
    window.addEventListener('storage', () => {
      tokenHandler();
    })
  }, []);

  useEffect(() => {
    tokenHandler();
  }, [location.pathname]);

  return (
    token && user ? <Outlet /> : <Navigate to="/login" />
  );
};


const Router = () => {
  return (
    <Routes>
      {/* OPEN ROUTES HERE */}
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<PageNotFound />} />

      <Route element={<ProtectedRoutes />}>
        {/* PROTECTED ROUTES HERE */}
        <Route path="/" element={<InStoreShopping />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/benefits" element={<Benefits />} />
        <Route path="/transactions" element={<Transactions />} />
      </Route>
    </Routes>
  );
};




export default Router;