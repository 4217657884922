import React from 'react';
import { NavLink } from 'react-router-dom';

const PageNotFound = () => (

  <main className="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8">
    <div className="text-center">
      <p className="text-base font-semibold text-base-600">404</p>
      <h1 className="mt-4 text-balance text-6xl font-semibold tracking-tight text-gray-900">
       Stranica nije pronađena
      </h1>
      <p className="mt-6 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">
        Žao nam je, nismo mogli da pronađemo stranicu koju tražite.
      </p>
      <div className="mt-10 flex items-center justify-center gap-x-6">
        <NavLink
          to="/"
          className="rounded-md bg-base-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-base-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-base-600"
        >
          Počenta stranica
        </NavLink>
        <NavLink to="/contact" className="text-sm font-semibold text-gray-900">
          Kontaktiraj podršku <span aria-hidden="true">&rarr;</span>
        </NavLink>
      </div>
    </div>
  </main>
);

export default PageNotFound;