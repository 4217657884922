import React, {useEffect, useState} from 'react';
import Input from 'components/Input';
import Button from 'components/Button';
import Datepicker from 'components/Datepicker';
import useFilters from 'modules/transaction/hooks/useFilters';
import SearchableDropdown from 'components/SearchableDropdown';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const statusOptions = [
  { value: 'finished', label: 'Završeno' },
  { value: 'cancelled', label: 'Stornirano' },
  { value: 'in_progress', label: 'U procesu' },
];

interface FiltersProps {
  onStartDateChange: (payload: any) => void;
  onEndDateChange: (payload: any) => void;
  onSearch: (payload: string) => void;
  onStatusChange: (payload: any) => void;
  onExport: () => void;
}

const Filters = ({ onStartDateChange, onEndDateChange, onSearch, onStatusChange, onExport }: FiltersProps) => {

    const {
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      searchString,
      setSearchString,
      status,
      setStatus
    } = useFilters();

  const startDateHandler = (date: string) => {
    setStartDate(date);
    onStartDateChange(date);
  }

  const endDateHandler = (date: string) => {
    setEndDate(date);
    onEndDateChange(date);
  }

  const searchHandler = (string: string) => {
    setSearchString(string);
    onSearch(string);
  }

  const statusHandler = (status: string) => {
    setStatus(status);
    onStatusChange(status);
  }

  const exportHandler = () => {
    onExport();
  }


  return (
    <div className="w-full flex items-center gap-x-4">
      <div className="w-2/12">
        <Datepicker
          value={startDate}
          format="dd.MM.yyyy"
          placeholder="Datum od"
          onChange={(value: any) => startDateHandler(value)}
        />
      </div>
      <div className="w-2/12">
        <Datepicker
          value={endDate}
          format="dd.MM.yyyy"
          placeholder="Datum od"
          onChange={(value: any) => endDateHandler(value)}
        />
      </div>
      <div className="w-5/12">
        <Input
          isSearch
          type='text'
          height='regular'
          debounceTime={400}
          onChange={searchHandler}
          placeholder="Pretraži po korisniku ili ID transakcije"
        />
      </div>
      <div className="w-3/12">
        <SearchableDropdown
          value={status}
          idField="value"
          isClearable={true}
          valueToShow="label"
          placeholder="Status"
          options={statusOptions}
          onClear={() => statusHandler('')}
          onChange={(status: any) => statusHandler(status)}
        />
      </div>
      <div className="w-2/12">
        <Button
          bg="primary"
          className="w-full"
          size="regular"
          onClick={exportHandler}
        >
          <FontAwesomeIcon icon={faDownload} className="mr-2" />
          Export (.xlsx)
        </Button>
      </div>
    </div>
  );
};

export default Filters;