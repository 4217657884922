import React, {useState} from 'react';
import Select, { Props } from 'react-select';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faXmarkCircle } from "@fortawesome/free-regular-svg-icons";

interface SearchableDropdownProps {
  options: any[];
  props: any;
  idField: any;
  label?: string;
  valueToShow: any;
  value: any;
  error?: any;
  onClear?: () => void;
  menuDirection?: any;
  multiselect?: boolean;
  placeholder?: string;
  onChange: (payload: any) => void;
  defaultValue?: any;
  disabled?: boolean;
  validationRules?: any;
}

const customClassNames = {
  control: () => "text-left h-10 w-full",
  option: () => "text-left",
  container: () => "rounded-lg",
}

const customStyles = {
  control: (baseStyles: any, state: any) => ({
      ...baseStyles,
      borderColor: "#e5e7eb",
      '&:hover': {
        borderColor: '#088FCCFF',
      },
      borderRadius: "0.5rem",
      borderWidth: "1px",
      cursor: 'pointer',
    }
  ),
  singleValue: (baseStyles: any, state: any) => (
    {
      ...baseStyles,
      color: "rgb(55 65 81)",
    }),
  placeholder: (baseStyles: any, state: any) => (
    {
      ...baseStyles,
      color: "#9CA3AF"
    }),
  indicatorsContainer: (baseStyles: any, state: any) => (
    {
      ...baseStyles,
      cursor:"pointer",
    })
};

const SearchableDropdown: any = ({
                                   options,
                                   onChange,
                                   value,
                                   defaultValue,
                                   label,
                                   multiselect,
                                   error,
                                   valueToShow,
                                   idField,
                                   onClear,
                                   menuDirection,
                                   disabled,
                                   placeholder,
                                   validationRules,
                                   ...props
                                 }: SearchableDropdownProps) => {

  const [isTouched, setIsTouched] = useState<boolean>(false);
  const isTouchedAndValid = isTouched && !(!error && ((value && value[0]) ||(value && Object.keys(value).length) === 0)) && validationRules;

  const selectHandler = (payload: any) => {
    setIsTouched(true);
    onChange(payload);
  }

  return (
    <div className="relative w-full">
      {label && (
        <label className="block text-gray-700 text-sm font-bold mb-1">
          {label}
        </label>
      )}

      <Select
        {...props}
        value={value}
        options={options}
        styles={customStyles}
        isDisabled={disabled}
        isMulti={multiselect}
        onChange={selectHandler}
        placeholder={placeholder}
        menuPlacement={menuDirection}
        defaultValue={defaultValue}
        classNames={customClassNames}
        getOptionValue={(option: any) => option[idField]}
        getOptionLabel={(option: any) => option[valueToShow]}
        className={`
          ${error && 'border-2 border-red-600'} 
          ${isTouchedAndValid && !disabled && 'border-2 border-green-600'} 
          ${!error && 'focus:ring-2 ring-primary-900'}
        `}
        theme={(theme: any) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "#046c9c",
          }
        })}
      />

      {value && onClear && (
        <span onClick={onClear} className={`absolute inset-y-0 ${multiselect ? 'right-16' : 'right-10'} ${label ? 'mt-6' : ''} h-10 z-20 ml-3 flex items-center justify-center pr-2 cursor-pointer`}>
          <FontAwesomeIcon icon={faXmarkCircle} className="text-red-600" />
        </span>
      )}

      {error && error.type && error.message && (
        <div className="absolute left-0 -bottom-5 text-red-600 flex items-center gap-x-1">
          <small className="pt-1">*{error.message}</small>
        </div>
      )}
    </div>
  );
}

export default SearchableDropdown;
