import React, { useEffect, useRef } from 'react';

import { toast } from 'react-toastify';
import Button from 'components/Button';
import Filters from 'modules/benefit/UI/Filters';
import GridView from 'modules/benefit/UI/GridView';
import ListView from 'modules/benefit/UI/ListView';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from 'store/modal/modal.store';
import { ConfirmDeleteDialog } from 'components/Dialogs';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import AddBenefitForm from 'modules/benefit/UI/AddBenefitForm';
import EditBenefitForm from 'modules/benefit/UI/EditBenefitForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { benefitActions, deleteFixedBenefit, getBenefits } from 'modules/benefit/services/benefit.store';

const Benefits = () => {

  const dispatch: any = useDispatch();
  const count: any = useSelector((state: any) => state.benefitStore.count);
  const nextPage: any = useSelector((state: any) => state.benefitStore.nextPage);
  const benefits: any = useSelector((state: any) => state.benefitStore.benefits);
  const filters: any = useSelector((state: any) => state.benefitStore.filters);

  const countRef = useRef(count);
  const nextPageRef = useRef(nextPage);

  useEffect(() => {
    nextPageRef.current = nextPage;
  }, [nextPage]);

  useEffect(() => {
    countRef.current = count === benefits.length;
  }, [count, benefits]);

  const openAddEmployeeForm = (benefit: any = null) => {
    let modalContent: React.JSX.Element = (
      <AddBenefitForm copyData={benefit} />
    );

    dispatch(
      modalActions.openModal({
        modalContent,
        modalProps: {
          title: 'Dodaj benefit',
          size: 'regular',
        },
      })
    );
  };

  const openEditEmployeeForm = (benefit: any, isPreview: boolean) => {
    let modalContent: React.JSX.Element = (
      <EditBenefitForm benefit={benefit} isPreview={isPreview} />
    );

    dispatch(
      modalActions.openModal({
        modalContent,
        modalProps: {
          title: isPreview ? 'Pregled benefita' : 'Ažuriraj benefit',
          size: 'regular',
        },
      })
    );
  };

  const fetchMoreFixedBenefits = async (isFetchMore: boolean): Promise<void> => {
    try {
      await dispatch(getBenefits(isFetchMore));
    } catch (e: any) {
      console.log(e, 'Err');
    }
  };

  const openDeletePrompt = (benefitId: number): void => {
    let modalContent: React.JSX.Element = (
      <ConfirmDeleteDialog
        title="Izbriši benefit"
        description="Da li zaista želiš da izbrišeš benefit?"
        onDelete={() => deleteFixedBenefitHandler(benefitId)}
      />
    );

    dispatch(
      modalActions.openModal({
        modalContent,
        modalProps: {
          closable: false,
          size: 'small',
        },
      })
    );
  };

  const deleteFixedBenefitHandler = (benefitId: any): void => {
    try {
      dispatch(deleteFixedBenefit(benefitId));
      dispatch(modalActions.closeModal());
      toast.success('Uspešno ste izbrisali benefit!');
    } catch (e: any) {
      dispatch(modalActions.closeModal());
      toast.error('Nešto nije u redu!');
    }
  };

  const stripHtmlTags = (input: string): string => {
    if (input) return input.replace(/<\/?[^>]+(>|$)/g, "");
    else return input;
  };

  const parseCategoryName = (name: any) => {
    if (name) return name.split('/')[1];
    return name;
  };

  useEffect(() => {
    return () => {
      dispatch(benefitActions.setNextPage(null));
      dispatch(benefitActions.setCount(0));
      dispatch(benefitActions.resetBenefits());
    }
  }, []);

  useEffect(() => {
    fetchMoreFixedBenefits(false);
  }, [filters.searchString, filters.sortBy, filters.ordering]);

  useEffect(() => {
    dispatch(benefitActions.resetBenefits());
    fetchMoreFixedBenefits(false);
  }, [filters.onlyActive]);

  useEffect(() => {
    const handleScroll = () => {
      let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight > document.documentElement.offsetHeight - 20;
      if (bottomOfWindow && nextPageRef.current && !countRef.current) {
        fetchMoreFixedBenefits(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    dispatch(benefitActions.setCount(0));
    dispatch(benefitActions.setNextPage(null));
    dispatch(benefitActions.resetBenefits());
  }, []);

  return (
    <>
      <section className="flex items-center gap-x-5">
        <div>
          <Button
            bg="primary"
            className="lg:w-[302px]"
            size="regular"
            onClick={() => openAddEmployeeForm()}
          >
            <FontAwesomeIcon icon={faPlus} className="mr-2"/>
            Dodaj benefit
          </Button>
        </div>
        {/*<div className="w-96">*/}
        {/*  <Input*/}
        {/*    isSearch*/}
        {/*    type="text"*/}
        {/*    height="regular"*/}
        {/*    classNames="w-full"*/}
        {/*    placeholder="Pretraži benefite po nazivu"*/}
        {/*    onChange={setSearchStringHandler}*/}
        {/*  />*/}
        {/*</div>*/}
      </section>

      <Filters />

      {filters.previewType === 'grid' &&
        <GridView
          benefits={benefits}
          onCopy={(benefit: any) => openAddEmployeeForm(benefit)}
          onEdit={(benefit: any) => openEditEmployeeForm(benefit, false)}
          onPreview={(benefit: any) => openEditEmployeeForm(benefit, true)}
          onDelete={(benefitId: any) => openDeletePrompt(benefitId)}
        />
      }

      {filters.previewType === 'list' &&
        <ListView
          benefits={benefits}
          onCopy={(benefit: any) => openAddEmployeeForm(benefit)}
          onEdit={(benefit: any) => openEditEmployeeForm(benefit, false)}
          onPreview={(benefit: any) => openEditEmployeeForm(benefit, true)}
          onDelete={(benefitId: any) => openDeletePrompt(benefitId)}
        />
      }
      {/*<ul className="my-9 space-y-4">*/}
      {/*  {benefits.length > 0 && benefits.map((benefit: any) =>*/}
      {/*    <li*/}
      {/*      key={benefit.id}*/}
      {/*      className="bg-white hover:bg-gray-50 flex items-center justify-between gap-x-5 shadow-sm rounded-lg px-5 h-24 transform hover:scale-[1.02] animation duration-200 ease-in-out">*/}
      {/*      <div className="w-1/2">*/}
      {/*        <div className="flex items-center gap-x-3 truncate">*/}
      {/*          <div>*/}
      {/*            {benefit.pictures && benefit.pictures.length > 0 ? (*/}
      {/*              <img*/}
      {/*                src={benefit.pictures[0].image}*/}
      {/*                alt="benefit"*/}
      {/*                className="w-28 h-16 rounded-lg border"*/}
      {/*              />*/}
      {/*            ) : (*/}
      {/*              <div className="border bg-white w-28 h-16 rounded-lg flex items-center justify-center">*/}
      {/*                <img*/}
      {/*                  src="assets/hob-logo.png"*/}
      {/*                  alt="benefit"*/}
      {/*                  className="w-24 rounded-lg transform scale-[0.85]"*/}
      {/*                />*/}
      {/*              </div>*/}
      {/*            )}*/}
      {/*          </div>*/}
      {/*          <div>*/}
      {/*            <p className="text-lg font-medium text-base-600 truncate">{benefit.name}</p>*/}
      {/*            <p className="font-medium text-gray-500 truncate">{benefit.id}</p>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div className="w-1/2 flex items-center justify-between">*/}
      {/*        <div className="w-9/12 mr-5">*/}
      {/*          /!*<div className="font-medium text-gray-500 text-ellipsis text-wrap text-sm truncate h-[74px] flex items-center overflow-hidden gap-x-2">*!/*/}
      {/*          /!*  <p className="flex items-start max-h-[75px]">{stripHtmlTags(benefit.description)}</p>*!/*/}
      {/*          /!*</div>*!/*/}
      {/*          <div>*/}
      {/*            <div className="flex items-center gap-x-5">*/}
      {/*              <p className="text-lg font-medium  text-base-600"><span*/}
      {/*                className="text-black">Cena:</span> {monetaryFormatter(benefit.price)} {benefit.currency}</p>*/}
      {/*              <p className="text-lg font-medium  text-base-600"><span*/}
      {/*                className="text-black">Popust:</span> {benefit.discount} %</p>*/}
      {/*            </div>*/}
      {/*            <p className="text-medium font-medium text-gray-500">*/}
      {/*              <span>Kategorija:</span> {benefit.categoryComputed ? parseCategoryName(benefit.categoryComputed[0].name) : '/'}*/}
      {/*            </p>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*        <div className="w-32 flex items-center justify-end gap-x-2 ml-auto">*/}
      {/*          <button*/}
      {/*            onClick={() => openEditEmployeeForm(benefit)}*/}
      {/*            data-tooltip-id="edit"*/}
      {/*            data-tooltip-content="Izmeni"*/}
      {/*            className="h-9 w-9 rounded-full flex items-center justify-center bg-base-600 hover:bg-base-500 text-white animation duration-200 ease-in-out">*/}
      {/*            <Tooltip id="edit"/>*/}
      {/*            <FontAwesomeIcon icon={faPencil}/>*/}
      {/*          </button>*/}
      {/*          <button*/}
      {/*            onClick={() => openAddEmployeeForm(benefit)}*/}
      {/*            data-tooltip-id="copy"*/}
      {/*            data-tooltip-content="Kopiraj"*/}
      {/*            className="h-9 w-9 rounded-full flex items-center justify-center bg-orange-550 hover:bg-amber-400 text-white animation duration-200 ease-in-out">*/}
      {/*            <Tooltip id="copy"/>*/}
      {/*            <FontAwesomeIcon icon={faCopy}/>*/}
      {/*          </button>*/}
      {/*          <button*/}
      {/*            onClick={() => openDeletePrompt(benefit.id)}*/}
      {/*            data-tooltip-id="delete"*/}
      {/*            data-tooltip-content="Izbriši"*/}
      {/*            className="h-9 w-9 rounded-full flex items-center justify-center bg-red-600 hover:bg-red-500 text-white animation duration-200 ease-in-out">*/}
      {/*            <Tooltip id="delete"/>*/}
      {/*            <FontAwesomeIcon icon={faTrashCan}/>*/}
      {/*          </button>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </li>*/}
      {/*  )}*/}
      {/*  {benefits.length === 0 &&*/}
      {/*    <div className="h-[55vh] flex items-center justify-center text-gray-600 text-sm font-medium">*/}
      {/*      Nema pronađenih benefita.*/}
      {/*    </div>*/}
      {/*  }*/}
      {/*</ul>*/}
    </>
  );
};

export default Benefits;