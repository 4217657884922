import React from 'react';
import { useSelector } from 'react-redux';
import { ThreeCircles } from 'react-loader-spinner';

const GlobalLoader = () => {

  const isOpenSidebar: boolean = useSelector((state: any) => state.sidebarStore.isOpen);

  return (
    <div className={`fixed left-0 top-0 h-screen w-screen backdrop-blur-[5px] border flex items-center justify-center z-50 animation duration-200 ease-in-out`}>
      <svg className="pulse-animation" viewBox="0 0 16 16" width="30px" height="16px" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <circle id="c1" className="switch-animation-c1" cx="8" cy={8} r="8" fill="#29B6F6"/>
        <circle id="c2" className="switch-animation-c2" cx="14" cy={8} r="8" fill="#1e4154"/>
      </svg>
    </div>
  );
};

export default GlobalLoader;
