import React, { useEffect, useState } from 'react';
import useWidth from 'hooks/useWidth';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { sidebarActions } from 'store/sidebar/sidebar.store';
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faIcons,
  faArrowRightArrowLeft,
  faChevronRight, faCartArrowDown,
} from "@fortawesome/free-solid-svg-icons";


const menuItems = [
  { id: 1, name: 'In-Store kupovina', icon: faCartArrowDown, url: '/' },
  { id: 2, name: 'Lista kupovina', icon: faArrowRightArrowLeft, url: '/transactions' },
  { id: 3, name: 'Benefiti', icon: faIcons, url: '/benefits'},
];


const Sidebar = () => {

  const dispatch: any = useDispatch();
  const isOpen: boolean = useSelector((state: any) => state.sidebarStore.isOpen);
  const location = useLocation();

  const toggleSidebar = (): void => {
    dispatch(sidebarActions.toggleSidebar());
  }

  return (
    <aside className={`fixed top-0 left-0 flex items-center h-[100vh] z-30`}>
      <div className={`h-[83vh] sidebar ${isOpen ? 'open' : 'close'} mt-20 ml-5 mx-auto rounded-xl bg-blur bg-gradient-to-r from-blur-1 via-blur-2 to-blur-1 shadow animation duration-200 ease-in-out`}>
        <ul className="pt-7">
          <li
            onClick={toggleSidebar}
            className={`w-5 h-5 mb-6 mx-auto flex items-center justify-center bg-white rounded-full cursor-pointer`}>
            <FontAwesomeIcon icon={faChevronRight}
                             className={`mx-auto ${isOpen ? 'transform rotate-180' : ''} animation duration-200 ease-in-out text-gray-500 hover:text-base-500`}/>
          </li>
          {menuItems.map((route: any) =>
            <NavLink key={route.id} to={route.url}>
              <li
                className={`${isOpen ? 'w-10/12' : 'w-14'} p-1 mt-2 ml-5 flex items-center justify-start hover:text-base-600 ${location.pathname === route.url ? 'text-base-500 bg-base-transparent_500' : 'text-gray-600'} hover:bg-white cursor-pointer rounded-lg animation duration-200 ease-in-out`}>
                <div className={`flex items-center gap-x-3 truncate ml-3 animation duration-200 ease-in-out`}>
                  <FontAwesomeIcon icon={route.icon} className="text-2xl mx-auto w-6"/>
                  {isOpen && <p className="truncate">{route.name}</p>}
                </div>
              </li>
            </NavLink>
          )}
        </ul>
      </div>
    </aside>
  );
};


export default Sidebar;
